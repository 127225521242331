



















































































































































































































































import Vue from 'vue';
import FormSwitch from '@/components/form/form-switch.vue';
import DatePicker from '@/components/form/date-picker.vue';
import popUpModal from '@/components/form/pop-up-modal.vue';
import errorMessage from '@/components/form/error-message.vue';
import priceCalculator from '@/service/price-calculator';
import { CASE_STATUS } from '@/constant/constants';
import COUNTRIES from '@/config/countires-list';
import CountriesList from '../../components/form/countries-list.vue';

export default Vue.extend({
  name: 'short-term-insurance',
  components: {
    FormSwitch,
    CountriesList,
    DatePicker,
    popUpModal,
    errorMessage,
  },
  props: ['assesment', 'isAPIObserver', 'insuranceData', 'caseStatus', 'AMSAPISendStatus'],
  data() {
    return {
      exhibitsValue: 0,
      insureExhibitonStand: true,
      exhibitionValueStand: null,
      exhibitionInPermanentBuilding: true,
      scope: 'Deutschland',
      guardedExibitions: true,
      artObjects: false,
      iso: 'DE',
      startOfExhibition: '',
      endOfExhibition: '',
      dePer: 0,
      euPer: 0,
      worldwidePer: 0,
      ...this.insuranceData,
      nameOfExhibitionFair: '',
      nameOfExhibitionLocation: '',
      name2: '',
      street: '',
      houseNo: '',
      postCode: '',
      place: '',
      country: '',
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      componentLoaded: false,
    };
  },
  methods: {
    firstPageDataChange() {
      this.$emit(
        'first-page-data-changed',
      );
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    onIso(value: string) {
      this.iso = value;
    },
    onContinent(value: string) {
      if (value === 'Deutschland') {
        this.dePer = 100;
        this.euPer = 0;
        this.worldwidePer = 0;
      } else if (value === 'Europa') {
        this.dePer = 0;
        this.euPer = 100;
        this.worldwidePer = 0;
      } else if (value === 'Welt') {
        this.dePer = 0;
        this.euPer = 0;
        this.worldwidePer = 100;
      }
    },
    onSubmit() {
      const data = {
        exhibitsValue: this.exhibitsValue,
        insureExhibitonStand: this.insureExhibitonStand,
        exhibitionValueStand: this.exhibitionValueStand,
        exhibitionInPermanentBuilding: this.exhibitionInPermanentBuilding,
        scope: this.scope,
        nameOfExhibitionFair: this.nameOfExhibitionFair,
        nameOfExhibitionLocation: this.nameOfExhibitionLocation,
        name2: this.name2,
        street: this.street,
        houseNo: this.houseNo,
        postCode: this.postCode,
        place: this.place,
        country: this.country,
        startOfExhibition: this.startOfExhibition,
        endOfExhibition: this.endOfExhibition,
        guardedExibitions: this.guardedExibitions,
        artObjects: this.artObjects,
      };

      const priceData = {
        BetriebsartAusstellung: this.assesment.operatingMode.BART_TEXT,
        VersicherungswertAusstellungsstand: this.exhibitionValueStand,
        Ausstellungsstand: this.insureExhibitonStand,
        Risikoanschrift: this.iso,
        Vertragsdauer: 'Einzelausstellung',
        Versicherungssumme: this.exhibitsValue,
        FesteGebäude: this.exhibitionInPermanentBuilding,
        Bewachung: this.guardedExibitions,
        Kunstgegenstände: this.artObjects,
        Geltungsbereich: [
          {
            Geltungsbereich: 'Deutschland',
            Value: this.dePer,
          },
          {
            Geltungsbereich: 'Europa',
            Value: this.euPer,
          },
          {
            Geltungsbereich: 'Weltweit',
            Value: this.worldwidePer,
          },
        ],
      };
      this.$emit('data-changed', 'dbf6bf43-a384-4f73-9cf6-bef01e8d5f58', priceData, data);
    },
    setValues(insuranceData: any) {
      if (insuranceData.exhibitionValueStand) {
        this.exhibitionValueStand = insuranceData.exhibitionValueStand;
      }
      if (insuranceData.insureExhibitonStand) {
        this.insureExhibitonStand = insuranceData.insureExhibitonStand;
      }
      if (insuranceData.Risikoanschrift) {
        this.iso = insuranceData.Risikoanschrift;
      }
      if (insuranceData.exhibitsValue) {
        this.exhibitsValue = insuranceData.exhibitsValue;
      }
      if (insuranceData.exhibitionInPermanentBuilding) {
        this.exhibitionInPermanentBuilding = insuranceData.exhibitionInPermanentBuilding;
      }
      if (insuranceData.guardedExibitions) {
        this.guardedExibitions = insuranceData.guardedExibitions;
      }
      if (insuranceData.Kunstgegenstände) {
        this.artObjects = insuranceData.Kunstgegenstände;
      }
      // this.dePer = insuranceData.Geltungsbereich[0].Value;
      // this.euPer = insuranceData.Geltungsbereich[1].Value;
      // this.worldwidePer = insuranceData.Geltungsbereich[2].Value;

      if (insuranceData.nameOfExhibitionFair) {
        this.nameOfExhibitionFair = insuranceData.nameOfExhibitionFair;
      }
      if (insuranceData.nameOfExhibitionLocation) {
        this.nameOfExhibitionLocation = insuranceData.nameOfExhibitionLocation;
      }
      if (insuranceData.name2) {
        this.name2 = insuranceData.name2;
      }
      if (insuranceData.street) {
        this.street = insuranceData.street;
      }
      if (insuranceData.houseNo) {
        this.houseNo = insuranceData.houseNo;
      }
      if (insuranceData.postCode) {
        this.postCode = insuranceData.postCode;
      }
      if (insuranceData.place) {
        this.place = insuranceData.place;
      }
      if (insuranceData.country) {
        this.country = insuranceData.country;
      }
      if (insuranceData.startOfExhibition) {
        this.startOfExhibition = new Date(insuranceData.startOfExhibition);
      }
      if (insuranceData.endOfExhibition) {
        this.endOfExhibition = new Date(insuranceData.endOfExhibition);
      }
    },
    disableAllInputs() {
      const inputs = document.querySelectorAll('input');
      for (let i = 0; i < inputs.length; i += 1) {
        inputs[i].disabled = true;
      }

      const selects = document.querySelectorAll('select');
      for (let i = 0; i < selects.length; i += 1) {
        selects[i].disabled = true;
      }
    },
    getCityByZipCode() {
      priceCalculator.checkZipCode(this.postCode).then((response) => {
        this.place = response.data.postalCode.city;
      });
    },
    selectScope() {
      const index = COUNTRIES.findIndex((country) => this.country === country.Land);
      this.scope = COUNTRIES[index].Geltungsbereich;
      this.onContinent(this.scope);
      this.iso = COUNTRIES[index]['ISO-Code'];
    },
  },
  watch: {
    country: 'selectScope',
    changeData: {
      handler(val) {
        if (this.componentLoaded) this.firstPageDataChange();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.insuranceData) {
      this.setValues(this.insuranceData);
    }
    if (this.isDisableAll) {
      this.disableAllInputs();
    }
  },
  computed: {
    changeData(): any {
      return [this.assesment.operatingMode.BART_TEXT,
        this.exhibitionValueStand,
        this.insureExhibitonStand,
        this.iso,
        this.exhibitsValue,
        this.exhibitionInPermanentBuilding,
        this.guardedExibitions,
        this.artObjects].join();
    },
    isDisableAll(): boolean {
      if (this.isAPIObserver) {
        return true;
      }
      const result =
        this.caseStatus === CASE_STATUS.CONTRACT ||
        this.caseStatus === CASE_STATUS.SUGGESTION ||
        this.caseStatus === CASE_STATUS.CONTRACT_NO_SIGNATURE || this.AMSAPISendStatus !== null ||
        this.caseStatus === CASE_STATUS.OFFER_REQUEST_SEND;

      if (result) {
        this.disableAllInputs();
      }
      return result;
    },
  },
  beforeUpdate() {
    this.componentLoaded = true;
  },
});
